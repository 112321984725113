@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
:root {
  --font-family-2: 'Ubuntu', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(to bottom left, #FF9E01, #F44C03);

 /* Created with https://www.css-gradient.com */
  --background: #316125;
  --background: -webkit-linear-gradient(bottom left, #316125, #1F7E2D);
  --background: -moz-linear-gradient(bottom left, #316125, #1F7E2D);
  --background: linear-gradient(to top right, #2a5a1e, #299b3a);
  --bannerbg: linear-gradient(to right, rgba(30, 33, 58, 0.8), rgba(13, 58, 40, 0.8)), url('../public/images/treatment-in-india.webp');
  --bg-footer: #04293A;

  --color-head: #362222;
  --color-subhead: #53565a;
  --footer-txt-color:#D1D1D1;
  --light-bg: #EFF5F5;
}

*{
  scroll-behavior: smooth;
  font-family: var(--font-family-2);
}

/* To hide scroll bar when overflow-scrollable
   used inside: 'Testimonial', 'Achieved' inside 'Home'*/
.hide-scroll::-webkit-scrollbar {
display: none;
}

.hide-scroll {
-ms-overflow-style: none; 
scrollbar-width: none; 
}
  
.tick span::before{
  content:"\2713";
  color: rgb(16, 196, 16);
  font-size:larger;
  margin-right: 0.5rem;
}

#verifyingEmail {
  right: 70%;
  width: 30%;
  height: 4px;
  background: rgb(238, 222, 9);
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  50%{
    right: 0;
  }
  100%{
    right: 70%;
  }
}

.skeleton{
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0%{
    background-color: hsl(200, 20%, 70%);
  }
  100%{
    background-color: hsl(200, 20%, 90%);
  }
}

.skeleton-text{
  width: 100%;
  height: 0.5rem;
  margin-bottom: .25rem;
  border-radius: 0.125rem;
}

input:focus, textarea:focus{
  outline:skyblue 2px solid;
}

input, textarea, select{
  border: 1px solid rgb(196, 192, 192);
}


.new-line {
  white-space: pre-line;
}


.x_main{
  color: rgb(71, 70, 70);
}

.x_contents{
  border: .5px solid rgb(200, 190, 190);
  padding: 2rem;
  background-color: white;
  font-size: small;
}

.xhead{
  text-align: left;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;

}

.x_main p,
.x_contents p,
.x_contents ul li{
  text-align: justify;
}

.x_contents h2{
  font-size: medium;
  font-weight: 500;
}

.office_info h2, 
.contact_info h2{
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

/* --pagination inside all hospital-- */
.paginationBttns{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: small;
}

.paginationBttns a{
  padding: 5px 9px;
  border-radius: 100%;
  font-weight: 700;
  background-color: white;
  color: black;
}

.paginationBttns a:hover{
  background-color: rgb(29, 22, 163);
  color: white;
}

.paginationActive a{
  background-color: rgb(40, 167, 31);
  color: white;
}

.paginationDisabled a{
  display: none;
}

/* ---pagination style end here---- */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


